import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  transform(list: any[], valueObj: string, nameObj: string, filterText: string): any {
    if (filterText?.length > 0) {
      return list ? list.filter(item => item[valueObj].toString().search(filterText) > -1)[0][nameObj] : null;
    } else {
      return null;
    }

  }

}
