import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  loggedInUserDetail: any;
  avatarData: any;
  public getScreenWidth: any;
  public getScreenHeight: any;
  @ViewChild('menu', {static: false}) menu: any;

  constructor(private appService: AppService, private router: Router) {
  }

  ngOnInit(): void {
    this.loggedInUserDetail = this.appService.getMe();
    this.changeText();
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): any {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  pageOpen(path: string): any {
    this.router.navigate([path], {
      // queryParams: {
      //   priceRange: {
      //     min: "1",
      //     max: "2"
      //   }
      // }
    }).then(() => {
        if (this.getScreenWidth <= 1000) {
          this.menu.nativeElement.click();
        }

      }
    )
    ;
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {}
    // };
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([path], navigationExtras)
    // );
    // window.open(url, '_self');
  }

  changeText(): any {
    if (this.loggedInUserDetail?.employee?.profile_pic?.image_hash) {
      this.appService.getImage(this.loggedInUserDetail?.employee?.profile_pic?.image_hash).subscribe(value => {
        this.avatarData = value;
      });
    } else {
      this.avatarData = 'https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found-300x169.jpg';
    }

  }

}
