<div class="container-fluid pt-4 px-4">
  <div class="bg-light rounded-top p-4">
    <div class="row">
      <div class="col-12 col-sm-6 text-center text-sm-start">
        &copy; <a href="#">Family Tree</a>, All Right Reserved.
      </div>
      <div class="col-12 col-sm-6 text-center text-sm-end">
        <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
        Designed By <a href="https://htmlcodex.com">Harwex Technology</a>
        <br />
        Distributed By <a class="border-bottom" href="https://themewagon.com" target="_blank">Remshad Medappil</a>
      </div>
    </div>
  </div>
</div>
