import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PagesComponent} from './pages/pages.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {LoginComponent} from './shared/login/login.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)},
    {path: 'routing', loadChildren: () => import('./pages/routing-test/routing-test.module').then(m => m.RoutingTestModule)},
    {path: 'employee', loadChildren: () => import('./pages/employee/employee.module').then(m => m.EmployeeModule)},
    {path: 'member', loadChildren: () => import('./pages/member/member.module').then(m => m.MemberModule)},
    {
      path: 'GeneralSettings',
      loadChildren: () => import('./pages/general-settings/general-settings.module').then(m => m.GeneralSettingsModule)
    }, {
      path: 'GlobalSettings',
      loadChildren: () => import('./pages/global-settings/global-settings.module').then(m => m.GlobalSettingsModule)
    },
    {
      path: 'UserType',
      loadChildren: () => import('./pages/user-type/user-type.module').then(m => m.UserTypeModule)
    },
  ]
},
  {path: 'login', component: LoginComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // <- comment this line for activate lazy load
      preloadingStrategy: PreloadAllModules,
      // useHash: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
