import {SharedModule} from './shared/shared.module';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PagesComponent} from './pages/pages.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {FooterComponent} from './themes/components/footer/footer.component';
import {AppInterceptor} from './themes/utils/app-interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppSettings} from './app.settings';
import {RoutingTestComponent} from './pages/routing-test/routing-test.component';
import {LoginComponent} from './shared/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {en_GB, NZ_I18N} from 'ng-zorro-antd/i18n';
import {DatePipe, registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {NzTableModule} from 'ng-zorro-antd/table';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzMessageModule} from 'ng-zorro-antd/message';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzRateModule} from 'ng-zorro-antd/rate';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzInputNumberModule} from 'ng-zorro-antd/input-number';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {EmployeeComponent} from './pages/employee/employee.component';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzCommentModule} from 'ng-zorro-antd/comment';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import { SearchComponent } from './themes/components/search/search.component';
import { MessageComponent } from './themes/components/message/message.component';
import { NotificationComponent } from './themes/components/notification/notification.component';
import { ProfileComponent } from './themes/components/profile/profile.component';
import {NzImageModule} from 'ng-zorro-antd/image';
import {NzLayoutModule} from 'ng-zorro-antd/layout';

registerLocaleData(en);


@NgModule({
  declarations: [
    NotFoundComponent,
    FooterComponent,
    LoginComponent,
    AppComponent,
    PagesComponent,
    RoutingTestComponent,
    EmployeeComponent,
    SearchComponent,
    MessageComponent,
    NotificationComponent,
    ProfileComponent],
    imports: [RouterModule,
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NzTableModule,
        NzFormModule,
        NzModalModule,
        NzInputModule,
        NzButtonModule,
        NzMessageModule,
        NzSelectModule,
        NzStepsModule,
        NzDatePickerModule,
        NzCheckboxModule,
        NzIconModule,
        NzCarouselModule,
        NzCardModule,
        NzProgressModule,
        NzDropDownModule,
        NzRateModule,
        NzGridModule,
        NzListModule,
        NzInputNumberModule,
        NzDividerModule,
        NzUploadModule,
        NzRadioModule,
        NzAlertModule,
        NzCollapseModule,
        NzPaginationModule,
        NzTabsModule,
        NzBadgeModule,
        NzToolTipModule,
        NzPopoverModule,
        NzAvatarModule,
        NzCommentModule,
        NzSwitchModule,
        NzBreadCrumbModule,
        NzSpinModule, SharedModule.forRoot({
            loadingImagePath: 'assets/img/loading.gif',
            errorImagePath: 'assets/img/not_found.png',
        }), NzImageModule, NzLayoutModule,
    ],
  providers: [
    AppSettings,
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true},
    {provide: NZ_I18N, useValue: en_GB},
    // { provide: NZ_DATE_LOCALE, useValue: enIN }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
