<div class="container-fluid">
  <div class="row h-100 align-items-center justify-content-center" style="min-height: 100vh;">
    <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">


      <!--      <div class="make-center ant-col-xs-24 ant-col-xl-12 text-center pt-5">-->
      <!--        <div class="sign-in-detail text-white text-center">-->
      <!--          <a class="sign-in-logo1" href="#"    style="margin: auto; align-content: center; overflow: hidden;"><img  src="../../../assets/img/logo3.png"  class="center img-fluid"-->
      <!--                                                                                                                    alt="logo"></a>-->

      <!--          <nz-carousel nzAutoPlay class="center hidden-mobile" >-->
      <!--            <div nz-carousel-content *ngFor="let s of slides">-->
      <!--              <img [src]="s.image" class="img-fluid" alt="logo">-->
      <!--              <h4 class="mb-5 imgLabel text-center">{{s.label}}</h4>-->
      <!--            </div>-->
      <!--          </nz-carousel>-->
      <!--        </div>-->
      <!--      </div>-->


      <div class="bg-light rounded p-4 p-sm-5 my-4 mx-3">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <a href="index.html" class="">
            <h3 class="text-primary"><i class="fa fa-hashtag me-2"></i>Family Tree</h3>
          </a>
          <h3>Sign In</h3>
        </div>


        <form nz-form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit()">
          <nz-form-item>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" formControlName="username" id="floatingInput"
                     placeholder="name@examplena.com">
              <label for="floatingInput">User Name</label>
            </div>
          </nz-form-item>
          <nz-form-item>
            <div class="form-floating mb-4">
              <input type="password" formControlName="password" class="form-control" id="floatingPassword"
                     placeholder="Password">
              <label for="floatingPassword">Password</label>
            </div>
          </nz-form-item>


          <div class="d-flex align-items-center justify-content-between mb-4">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">Check me out</label>
            </div>
            <a href="">Forgot Password</a>
          </div>
          <button type="submit" [disabled]="!loginForm.valid" class="btn btn-primary py-3 w-100 mb-4">Sign In</button>
          <p class="text-center mb-0">Don't have an Account? <a (click)="signUp()">Sign Up</a></p>
        </form>
      </div>
    </div>
  </div>
</div>


<!--<div class="wrapper">-->
<!--<section class="sign-in-page">-->
<!--  <div class="ant-container p-0">-->

<!--    <div class="ant-row no-gutters full-height" >-->
<!-- -->
<!--  <div class="make-center  ant-col-xs-24 ant-col-xl-12  bg-purple text-white pt-5 ant-motion-collapse">-->
<!--    <div class="sign-in-from">-->
<!--      <h3 class="text-white mb-4 text-center white">Family Tree</h3>-->
<!--      <h1 class="mb-0 text-white white">Sign in</h1>-->
<!--      <p class="white">Enter your email address and password to access.</p>-->

<!--      <form nz-form [formGroup]="loginForm" class="login-form mt-4 text-white" (ngSubmit)="onLoginFormSubmit()">-->
<!--        <nz-form-item>-->
<!--          <nz-form-control nzErrorTip="Please input your username!">-->
<!--            <div class="form-group">-->
<!--              <label>Email address</label>-->
<!--              <input type="text" nz-input formControlName="username" nzSize="large" placeholder="Email address"/>-->
<!--            </div>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->
<!--        <nz-form-item>-->
<!--          <nz-form-control nzErrorTip="Please input your Password!">-->
<!--            <div class="form-group">-->
<!--              <label>Password</label>-->
<!--              <input type="password" nz-input formControlName="password" nzSize="large" placeholder="Password"/>-->
<!--            </div>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item>-->

<!--        <div nz-row class="login-form-margin">-->
<!--          <div nz-col [nzSpan]="12">-->
<!--            <label nz-checkbox nzSize="large" formControlName="remember_me">-->
<!--              <span>Remember me</span>-->
<!--            </label>-->
<!--          </div>-->

<!--          <div nz-col [nzSpan]="12" class="text-right">-->
<!--            <button [disabled]="!loginForm.valid" nz-button class="login-form-button login-form-margin"-->
<!--                    [nzType]="'primary'">Log in-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--    </div>-->
<!--  </div>-->

<!--    </div>-->


<!--  </div>-->
<!--</section>-->
<!--</div>-->

