import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ageFromDob'
})
export class AgeFromDobPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const timeDiff = Math.abs(Date.now() - new Date(value).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

}
