import {Component, OnInit} from '@angular/core';
import {AppService} from 'src/app/app.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private appService: AppService
  ) {
  }

  public loginForm: FormGroup;

  slides = [
    {label: 'Image Title', image: '../../../assets/img/2.png'},
    {label: 'Image Title', image: '../../../assets/img/1.jpeg'},
    {label: 'Image Title', image: '../../../assets/img/1.jpeg'}
  ];

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
      // corporate_id: [null],
      remember_me: false
    });
  }

  public onLoginFormSubmit(): void {
    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
    if (this.loginForm.valid) {
      console.log(this.loginForm.controls['username'].value);
      this.appService.getAuthorizationToken(this.loginForm.controls['username'].value, this.loginForm.controls['password'].value).subscribe(result => {
        localStorage.setItem('accountAccessToken', result['token']);

        this.appService.getLoggedInUserDetail().subscribe(res => {
          localStorage.setItem('me', btoa(encodeURIComponent(JSON.stringify(res))));
          if (res['login_count'] === 1) {
            this.router.navigate(['/member/edit'], {queryParams: {id: res['employee']['id']}});
          } else {
            this.router.navigate(['/']);
          }

        });


      }, error => {
        Swal.fire({icon: 'error', title: 'Invalid username or password', text: error.error.message});
      });
      //
    }
  }

  signUp(): any {
    Swal.fire({icon: 'error', title: 'Public signup disabled', text: 'Please contact admin for account creation\nph:9447796296'});
  }
}
