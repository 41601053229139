import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Employee, SettingOption} from './app.models';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {NavigationExtras, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  apiEndPoint = environment.apiEndPoint;

  constructor(public http: HttpClient, private domSanitizer: DomSanitizer, public router: Router) {
  }


  public getImage(url): Observable<SafeUrl> {
    return this.http
      .get(this.apiEndPoint + '/api/images/' + url, {observe: 'response', responseType: 'blob', headers: this.getHeaders()})
      .pipe(
        map((response: HttpResponse<Blob>) => URL.createObjectURL(response.body)),
        map((unsafeBlobUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)),
        // if the request errors out we return the error image's path value
        // catchError((err) => console.log(err))
      );
    // return this.http.get(this.apiEndPoint + url,
    //   {observe: 'response', responseType: 'blob', headers: this.getHeaders()}
    // );
  }

  // making get request
  public get<T>(url): any {
    return this.http.get<T>(this.apiEndPoint + url, {
      headers: this.getHeaders()
    });
  }


  // // making get request
  // public getBlob<T>(url): any {
  //   return this.http.get<T>(this.apiEndPoint + url, {
  //     // headers: this.getHeaders().set('X-Requested-With', '')
  //     headers: this.getHeadersForBlob()
  //
  //   });
  // }

  // making delete request
  public delete(url) {
    return this.http.delete(this.apiEndPoint + url, {
      headers: this.getHeaders()
    });
  }

  // making post request
  public post(url, data) {
    return this.http.post(this.apiEndPoint + url, data, {
      headers: this.getHeaders()
    });
  }

  public put(url, data) {
    return this.http.put<any>(this.apiEndPoint + url, data, {
      headers: this.getHeaders()
    });
  }

  public postFormData(url, data) {
    let isformData = true;
    return this.http.post(this.apiEndPoint + url, data, {
      headers: this.getHeaders(isformData)
    });
  }

  public putFormData(url, data) {
    let isformData = true;
    return this.http.put(this.apiEndPoint + url, data, {
      headers: this.getHeaders(isformData)
    });
  }

  public getHeaders(isformData = false) {
    let headers = new HttpHeaders().set('X-Requested-With', 'XMLHttpRequest')
      .set('Access-Control-Allow-Origin', '*');
    if (isformData !== true) {
      headers = headers.set('content-type', 'application/json');
    }
    if (localStorage.getItem('accountAccessToken')) {
      headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accountAccessToken'));
    }
    return headers;
  }


  // getHeadersForBlob(isformData = false) {
  //   let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
  //   if (isformData !== true) {
  //     headers = headers.set('content-type', 'application/json');
  //   }
  //   headers.set(responseType, 'text' as 'json');
  //
  //   if (localStorage.getItem('accountAccessToken')) {
  //     headers = headers.set('Authorization', 'Bearer ' + localStorage.getItem('accountAccessToken'));
  //   }
  //   headers.set('Accept-Encoding', 'gzip, deflate, br');
  //   return headers;
  // }

  public getAuthorizationToken(username, password) {
    let authorize = btoa(username + ':' + password);
    let headers = this.getHeaders();
    headers = headers.set('Authorization', 'basic ' + authorize);
    return this.http.post(this.apiEndPoint + '/api/tokens/', '', {
      headers: headers
    });
  }

  public getLoggedInUserDetail() {
    return this.get('/api/users/me');
  }

  public getMe() {
    if (localStorage.getItem('me')) {
      return JSON.parse(decodeURIComponent(atob(localStorage.getItem('me'))));
    }

    return null;
  }


  // -----------------------------
  // Employee related APIs
  // -----------------------------
  // public getEmployees(rr: EmployeeRetrieveRequest = null): Observable<Employee[]> {
  //
  //   let filters = {
  //     // 'page': rr ? rr.page : 1,
  //     // 'per_page': rr ? rr.per_page : 500,
  //     // 'order_by': rr ? rr.order_by : 'created_on',
  //     // 'sort_order': rr ? rr.sort_order : 'desc',
  //     // 'ids': rr ? rr.ids : '',
  //     // 'display_name': rr ? rr.display_name : '',
  //     // 'reporting_manager': rr ? rr.reporting_manager : '',
  //     // // "campaign_item_id": rr ? rr.campaign_item_id: '',
  //     // 'business_unit_ids': rr ? rr.business_unit_ids : '',
  //     // 'function_ids': rr ? rr.function_ids : '',
  //     // 'location_ids': rr ? rr.location_ids : '',
  //     // 'department_ids': rr ? rr.department_ids : ''
  //   };
  //
  //   return this.get<Employee[]>('/api/employees?f=' + btoa(JSON.stringify(filters)));
  // }

  public updateEmployee(data, id): Observable<any> {
    return this.putFormData('/api/employees/' + id, data);
  }

  public updatePassword(data, id): Observable<any> {
    return this.putFormData('/api/updatePassword/' + id, data);
  }

  public createEmployee(data): Observable<any> {
    return this.postFormData('/api/employees', data);
  }

  public deleteMember(id): Observable<any> {
    return this.delete('/api/members/' + id);
  }

  public getEmployeeById(id): any {
    return this.get('/api/employees/' + id);
  }

  public removeUserToken(): any {
    return this.delete('/api/tokens/');
  }

  public getMembersByRelation(text: string): any {
    return this.get('/api/getMembers/?text=' + text);
  }

  public createMember(data: any): any {
    return this.post('/api/members', data);
  }


  public postImage(data: any): any {
    return this.postFormData('/api/images', data);
  }


  public createSettings(data: any): any {
    return this.post('/api/select_options', data);
  }

  public getSettings(rr: SettingOption = null): any {
    const filters = {
      page: 1,
      per_page: 100,
      order_by: 'created_on',
      sort_order: 'desc',
      ids: '',
      option_type: rr ? rr.option_type : '',
      option_name: rr ? rr.option_name : '',
      option_value: rr ? rr.option_value : '',
      language: rr ? rr.language : '',
      option_status: rr ? rr.option_status : '',
    };

    return this.get('/api/select_options?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }

  public putSettings(data, id): any {
    return this.put('/api/select_options/' + id, data);
  }

  public deleteSettings(id): any {
    return this.delete('/api/select_options/' + id);
  }

  public getMembers(rr: Employee = null): Observable<Employee[]> {
    const filters = {
      page: rr ? rr.page : 1,
      per_page: rr ? rr.per_page : 10,
      order_by: rr ? rr.order_by : 'created_on',
      sort_order: rr ? rr.sort_order : 'desc',
      ids: rr ? rr.ids : '',
      family_name: rr ? rr.family_name : '',
      member_name: rr ? rr.member_name : '',
      age_from: rr ? rr.age_from : '',
      age_to: rr ? rr.age_to : '',
      f_name: rr ? rr.f_name : '',
      m_name: rr ? rr.m_name : '',
      pincode: rr ? rr.pincode : '',
      qualification: rr ? rr.qualification : '',
      job: rr ? rr.job : '',
      blood_group: rr ? rr.blood_group : '',
      blood_donate: rr ? rr.blood_donate : '',
      relationship: rr ? rr.relationship : '',
      zakath_giving: rr ? rr.zakath_giving : '',
      place: rr ? rr.place : '',
      gender: rr ? rr.gender : '',
      physical_status: rr ? rr.physical_status : '',
      social_interest: rr ? rr.social_interest : '',
      personal_interest: rr ? rr.personal_interest : ''

    };


    return this.get<Employee[]>('/api/members?f=' + btoa(encodeURIComponent(JSON.stringify(filters))));
  }


  public getTree(startId: number, limit: number): any {
    return this.get('/api/tree?start_id=' + startId + '&limit=' + limit);
  }

  public getFoldTree(startId: number, limit: number): any {
    return this.get('/api/foldTree?start_id=' + startId + '&limit=' + limit);
  }

  public getFamilyName(familyName: string): any {
    return this.get('/api/getFamily?familyName=' + familyName);

  }

  getDropDownText(id, object): any {
    return _.filter(object, (o) => {
      return (_.includes(id, o.id));
    });

  }

  updateMember(data: any, empId: any): any {
    return this.put('/api/members?id=' + empId, data);
  }

  createUserRole(data: any): any {
    return this.post('/api/user_role/', data);
  }

  updateUserRole(id, data: any): any {
    return this.put('/api/user_role/' + id, data);
  }

  getUserRole(): any {
    return this.get('/api/user_role/');
  }

  deleteUserRole(id): any {
    return this.delete('/api/user_role/' + id);
  }

  createLanguage(data: any): any {
    return this.post('/api/language_labels/', data);
  }

  updateLanguage(id, data: any): any {
    return this.put('/api/language_labels/' + id, data);
  }

  changeUserLanguage(id, data: any): any {
    return this.put('/api/language_user/' + id, data);
  }

  deleteLanguage(id): any {
    return this.delete('/api/language_labels/' + id);
  }

  getLanguage(): any {
    return this.get('/api/language_labels/');
  }

  dashboardUsersData(): any {
    return this.get('/api/dashboard/usersData');
  }

  dashboardSelfData(): any {
    return this.get('/api/dashboard/selfData');
  }




  DataURIToBlob(dataURI: string): any {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result.toString()));
    reader.readAsDataURL(img);
  }

  pageOpen(path: string, ids = null): any {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: ids != null ? ids : null
      },
    };
    this.router.navigate([path], navigationExtras);
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {
    //     id: item.id
    //   },
    // };
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([path], navigationExtras)
    // );
    // window.open(url, '_blank');
  }
}
