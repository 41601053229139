import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings, Settings} from './app.settings';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {
    title = 'family-web';

    public settings: Settings;

    constructor(public appSettings: AppSettings, public router: Router) {
        this.settings = this.appSettings.settings;
    }

    ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    window.scrollTo(0, 0);
                });
            }
        });
    }

}
