<div class="nav-item dropdown">
  <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
    <i class="fa fa-envelope me-lg-2"></i>
    <span class="d-none d-lg-inline-flex">Message</span>
  </a>
  <div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
    <a href="#" class="dropdown-item">
      <div class="d-flex align-items-center">
        <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
        <div class="ms-2">
          <h6 class="fw-normal mb-0">Jhon send you a message</h6>
          <small>15 minutes ago</small>
        </div>
      </div>
    </a>
    <hr class="dropdown-divider">
    <a href="#" class="dropdown-item">
      <div class="d-flex align-items-center">
        <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
        <div class="ms-2">
          <h6 class="fw-normal mb-0">Jhon send you a message</h6>
          <small>15 minutes ago</small>
        </div>
      </div>
    </a>
    <hr class="dropdown-divider">
    <a href="#" class="dropdown-item">
      <div class="d-flex align-items-center">
        <img class="rounded-circle" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">
        <div class="ms-2">
          <h6 class="fw-normal mb-0">Jhon send you a message</h6>
          <small>15 minutes ago</small>
        </div>
      </div>
    </a>
    <hr class="dropdown-divider">
    <a href="#" class="dropdown-item text-center">See all message</a>
  </div>
</div>
