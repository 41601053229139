<div id="content-page" class="content-page">
    <div class="container">
      <!-- <div class="nz-demo-typography-title">
        <h3 nz-typography>List of Annual Priorities</h3></div> -->
      <div class="row">
        <div class="col-sm-12">
          <div class="iq-card">
            <div class="iq-card-body">
              <!-- <button nz-button nzType="primary" (click)="showCreateModal()">
                <span>Create New</span>
              </button> -->
              <h3 class="py-4">List of Employees</h3>
              <!-- <div class="row my-4">
                <div class="col-md-4">
                  <h3 class="text-left plusIcon"> 
                    <i nz-icon class="mb-4" routerLink="employee-detail" nzType="plus-circle" nzTheme="fill"></i></h3>
                    <button nz-button nzType="primary" (click)="importModal()">
                      <span>Import</span>
                    </button>
                  </div>
                  <div class="col-md-4"></div>
              </div> -->
              <nz-modal
                [(nzVisible)]="isFormVisible"
                nzTitle="Bulk Import"
                (nzOnCancel)="handleModalCancel()"
                (nzOnOk)="handleModalSubmit()"
                [nzOkText]="fileUploading ? 'fileUploading' : 'Start Upload'"
                nzOkType="primary"
                [nzOkLoading]="fileUploading"
                [nzOkDisabled]="fileList.length == 0"
              >
              <button
                nz-button
                [nzType]="'primary'"
                [nzLoading]="downloading"
                class="mr-3"
              >
                Sample CSV Download
              </button>
              <nz-upload
               [(nzFileList)]="fileList"
               [nzBeforeUpload]="beforeUpload"
               >
                <button [disabled]="fileList.length == 1" nz-button><i nz-icon nzType="upload"></i>Select File</button>
              </nz-upload>
              <!-- <button
                nz-button
                [nzLimit]=1
                [nzFileType]="csv"
                [nzType]="'primary'"
                [nzLoading]="fileUploading"
                (click)="handleUpload()"
                [disabled]="fileList.length == 0"
                style="margin-top: 16px"
              >
                {{ fileUploading ? 'fileUploading' : 'Start Upload' }}
              </button> -->
              </nz-modal>
              <div class="d-flex justify-content-between align-items-center">
                <a class="add-btn"  [routerLinkActive]="'employee-detail'"  title="Create New"><i class="ri-add-circle-fill"></i></a>
                <button nz-button nzType="primary" (click)="showImportModal()">
                  <span>Import</span>
                </button>
              </div>
              
              <nz-table
                nzBordered
                nzShowSizeChanger
                [nzFrontPagination]="false"
                [nzLoading]="loading"
                [nzTotal]="total"
                [nzPageSize]="pageSize"
                [nzPageIndex]="pageIndex"
                (nzQueryParams)="onQueryParamsChange($event)"
                [nzData]="employees"
              >
                <thead>
                  <tr>
                    <!-- <th nzColumnKey="id" [nzSortFn]="true">Id</th>
                    <th nzColumnKey="firstName" [nzSortFn]="true">First Name</th>
                    <th nzColumnKey="middleName" [nzSortFn]="true">Middle Name</th>
                    <th nzColumnKey="lastName" [nzSortFn]="true">Last Name</th>
                    <th nzColumnKey="displayName" [nzSortFn]="true">Display Name</th>
                    <th nzColumnKey="designation" [nzSortFn]="true">Designation</th>
                    <th nzColumnKey="dob" [nzSortFn]="true">Date of Birth</th>
                    <th nzColumnKey="gradeID" [nzSortFn]="true">Grade ID</th>
                    <th nzColumnKey="userID" [nzSortFn]="true">User ID</th>
                    <th>Action</th> -->
                    <th>Profile</th>
                    <th>Display Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Department</th>
                    <th>Action</th>
                    
                  </tr> 
                </thead>
                <tbody>
                  <tr *ngFor="let emp of employees">
                    <td>
                      <!-- {{ emp?.profile_pic?.original_image_url}} -->
                      <!-- <nz-avatar  nzSize="large" nzIcon="user" [nzSrc]="'ImageUrlSanitizer(emp?.profile_pic?.original_image_url)'"></nz-avatar> -->
                      <!-- <img [src]="emp?.profile_pic?.thumb_image_url_1" class="img-fluid rounded-circle mr-3 " alt="test" srcset=""> -->
                      <nz-avatar *ngIf="emp?.profile_pic?.thumb_image_url_1" [nzSize]="64" [nzSrc]="emp?.profile_pic?.thumb_image_url_1" ></nz-avatar>
                      <nz-avatar *ngIf="!emp?.profile_pic?.thumb_image_url_1" [nzSize]="64" nzIcon="user" ></nz-avatar>

                    </td>
                    <td>{{emp.display_name}}</td>
                    <td>{{emp.user.email}}</td>
                    <td>
                      {{emp.role_id ? emp.role.role_name : "--"}}
                    </td>
                    <td>
                      {{emp.department_id ? emp.department.name : "--"}}
                    </td>
                    <!-- <td>{{ emp.id }}</td>
                    <td>{{ emp.first_name }}</td>
                    <td>{{ emp.middle_name }}</td>
                    <td>{{ emp.last_name }}</td>
                    <td>{{ emp.display_name }}</td>
                    <td>{{ emp.designation }}</td>
                    <td>{{ emp.dob }}</td>
                    <td>
                      <a [routerLink]="['/grade']" [queryParams]="{id: emp.grade_id}">{{ emp.grade.grade_name }}</a>
                    </td>
                    <td>{{ emp.user_id }}</td> -->
                    <td>
                      <a class="mr-2" [routerLink]="'employee-detail/'+emp.id"> <i class="ri-pencil-fill grayIcon"></i></a>
                      <a (click)="remove(emp)"><i class="ri-delete-bin-6-fill grayIcon"></i></a>
                    </td>
                    
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
