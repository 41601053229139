import {ChangeDetectorRef, Inject, InjectionToken, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {AppService} from '../../app.service';
import {environment} from '../../../environments/environment';

export const THIS_DOT_LOADING_IMAGE_PATH = new InjectionToken<string>('assets/img/loading.gif');
export const THIS_DOT_ERROR_IMAGE_PATH = new InjectionToken<string>('assets/img/not_found.png');

@Pipe({
  name: 'useHttpImgSrc',
  pure: false
})
export class UseHttpImgSrcPipe implements PipeTransform, OnDestroy {


  private subscription = new Subscription();
  private loadingImagePath!: string;
  private errorImagePath!: string;
  private latestValue!: string | SafeUrl;
  private transformValue = new BehaviorSubject<string>('');
  apiEndPoint = environment.apiEndPoint;

  constructor(private appService: AppService,
              private httpClient: HttpClient,
              private domSanitizer: DomSanitizer,
              private cdr: ChangeDetectorRef,
              @Inject(THIS_DOT_LOADING_IMAGE_PATH) private defaultLoadingImagePath: string,
              @Inject(THIS_DOT_ERROR_IMAGE_PATH) private defaultErrorImagePath: string
  ) {
    this.setUpSubscription();
  }

  transform(imagePath: string,
            loadingImagePath?: string,
            errorImagePath?: string): string | SafeUrl {

    this.setLoadingAndErrorImagePaths(loadingImagePath, errorImagePath);
    this.transformValue.next(imagePath);
    // we always return the latest value
    return this.latestValue || this.loadingImagePath;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setUpSubscription(): void {
    const transformSubscription = this.transformValue
      .asObservable()
      .pipe(
        filter((v): v is string => !!v),
        switchMap((imagePath: string) => this.httpClient
          .get(this.apiEndPoint + imagePath, {observe: 'response', responseType: 'blob', headers: this.appService.getHeaders()})
          .pipe(
            map((response: HttpResponse<Blob>) => URL.createObjectURL(response.body)),
            map((unsafeBlobUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)),
            filter((blobUrl) => blobUrl !== this.latestValue),
            // if the request errors out we return the error image's path value
            catchError(() => of(this.errorImagePath))
          )
        ),
        tap((imagePath: string | SafeUrl) => {
          this.latestValue = imagePath;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
    this.subscription.add(transformSubscription);
  }

  private setLoadingAndErrorImagePaths(
    loadingImagePath: string = this.defaultLoadingImagePath,
    errorImagePath: string = this.defaultErrorImagePath
  ): void {
    if (this.loadingImagePath && this.errorImagePath) {
      return;
    }
    this.loadingImagePath = loadingImagePath;
    this.errorImagePath = errorImagePath;
  }
}
