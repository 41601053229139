import {Component, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  loggedInUser: any;
  avatarData: any;

  constructor(private appService: AppService, private router: Router) {
  }

  ngOnInit(): void {
    this.loggedInUser = this.appService.getMe();
    this.changeText();
  }

  changeText(): any {
    if (this.loggedInUser?.employee?.profile_pic?.image_hash) {
      this.appService.getImage(this.loggedInUser?.employee?.profile_pic?.image_hash).subscribe(value => {
        this.avatarData = value;
      });
    } else {
      this.avatarData = 'https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found-300x169.jpg';
    }

  }

  logout() {

    localStorage.removeItem('me');
    localStorage.removeItem('accountAccessToken');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('Cookies');
    this.appService.removeUserToken().subscribe((res) => {
      Swal.fire({icon: 'success', text: 'Logged out successfully', title: 'Logged Out'});
    }, (error) => {
      // Swal.fire({icon: 'error', text: 'There is an error to logged out', title: 'There is an Error' + error.error.message});
    });
  }

}
