import {DomSanitizer} from '@angular/platform-browser';
import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Employee} from 'src/app/app.models';
import {FormBuilder} from '@angular/forms';
import {AppService} from 'src/app/app.service';
import {NzTableQueryParams} from 'ng-zorro-antd/table';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzUploadFile} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
  empImageSafeUrl: string;
  total = 1;
  // These are the Employees which are displayed in the list screen.
  employees: Employee[] = [];
  employeeId: number;

  loading = true;
  pageSize = 10;
  pageIndex = 1;

  // form shown inside the modal.
  isFormVisible = false;
  downloading = false;
  fileUploading = false;
  fileList: NzUploadFile[] = [];
  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  constructor(private fb: FormBuilder,
              private appService: AppService,
              private message: NzMessageService,
              private modal: NzModalService,
              private datepipe: DatePipe,
              private sanitizer: DomSanitizer) {
  }


  showImportModal(): void {
    this.isFormVisible = true;
  }

  handleModalSubmit(): void {
    this.fileUploading = true;
    const formData = new FormData();
    // tslint:disable-next-line:no-any
    this.fileList.forEach((file: any) => {
      formData.append('input_file', file);
    });
    // this.appService.importEmployees(formData).subscribe((res: any) => {
    //     this.fileList = [];
    //     this.isFormVisible = false;
    //     this.fileUploading = false;
    //
    //     this.message.create('success', res.message, {
    //         nzDuration: 5000
    //     });
    // }, error => {
    //     this.fileList = [];
    //     this.isFormVisible = false;
    //     this.fileUploading = false;
    //
    //     const errorMessage = environment.production ? error.error.error : error.error.message;
    //     this.message.create('error', errorMessage, {
    //         nzDuration: 10000
    //     });
    //
    // });
  }

  handleModalCancel(): void {
    this.fileList = [];
    this.isFormVisible = false;
  }

  ngOnInit(): void {
  }

  public remove(entity: Employee): any {
    // const index: number = this.employees.indexOf(entity);
    //
    // if (index !== -1) {
    //   this.modal.confirm({
    //     nzTitle: 'Confirm Delete',
    //     nzContent: 'Are you sure, you want to delete ' + entity.member_name + '?',
    //     nzOnOk: () =>
    //       new Promise((resolve, reject) => {
    //
    //         this.appService.deleteEmployee(entity.id).subscribe(res => {
    //           this.loadDataFromServer(this.pageIndex, this.pageSize, null, null);
    //
    //           this.message.create('success', 'Record Deleted', {
    //             nzDuration: 5000
    //           });
    //
    //           resolve(res);
    //         }, error => {
    //
    //           // let errorMessage = environment.production ? error.error.error : error.error.message;
    //           this.message.create('error', error.error.message, {
    //             nzDuration: 10000
    //           });
    //
    //           reject();
    //         });
    //
    //       }).catch(() => console.log('Oops errors!'))
    //   });
    // }

  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const {pageSize, pageIndex, sort} = params;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadDataFromServer(pageIndex, pageSize, sortField, sortOrder);
  }

  loadDataFromServer(pageIndex: number, pageSize: number, sortField: string | null, sortOrder: string | null): void {
    this.loading = true;

    // TODO: Create a filter form and fetch data of the filter from there.

    const rr = new Employee(null);
    rr.sort_order = sortOrder ? sortOrder : 'desc';
    rr.order_by = sortField ? sortField : 'created_on';
    rr.per_page = pageSize;
    rr.page = pageIndex;

    // this.appService.getEmployees(rr).subscribe(data => {
    //     this.loading = false;
    //     this.total = data['_meta']['total_items'];
    //     this.employees = data['items'];
    // });
  }

  // ImageUrlSanitizer(url: string) {
  //   if (!url) {
  //     return null;
  //   } else {
  //     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  //   }
  // }
  // downloadSampleCSV() {
  //     this.downloading = false;
  //
  //     let rr = new EmployeeCSVSampleTemplateDocRetrieveRequest();
  //     rr.caption = 'Employee upload template';
  //     this.appService.getEmployeeCSVSampleTemplateDoc(rr).subscribe(data => {
  //         this.downloading = false;
  //
  //         var hiddenElement = document.createElement('a');
  //         hiddenElement.href = data['items'][0].document_url;
  //         hiddenElement.target = '_blank';
  //         hiddenElement.click();
  //     });
  // }
}
