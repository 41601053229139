export class RetrieveRequest {
  constructor(public page: number = 1,
              public per_page: number = 20,
              public order_by: string = 'id',
              public sort_order: string = 'desc',
              public ids: number[] = []) {
  }
}

export class Image {
  constructor(public id: number,
              public caption: string,
              public image_type: string,
              public original_image_url: string,
              public thumb_image_url_1: string,
              public thumb_image_url_2: string,
              public parent_entity: string,
              public parent_id: number) {
  }
}

export class ListOfValueRetrieveRequest extends RetrieveRequest {
  constructor(public lov_type: string = '', public parent_id: number = null) {
    super();
  }
}


export class ListOfValue {
  constructor(public id: number,
              public parent_id: number,
              public type: string,
              public value: string,
              public display: string,
              public selected: boolean = false) {
  }
}


export class SettingOption extends RetrieveRequest {
  constructor(public id: number = null, public option_status: boolean = true, public option_name: string = '', public option_value: string = '', public option_type: string = '', public language: string = '', public option_select: string = '') {
    super();
  }
}

export class Employee extends RetrieveRequest {
  constructor(public id: number = null,
              public member_name: string = '',
              public display_name: string = '',
              public dob: string = '',
              public user_id: number = null,
              public profile_pic: any = null,
              public about_me: string = null,
              public address: string = null,
              public phoneNumber: string = null,
              public phoneNumberPrefix: string = null,
              public role_id: number = null,
              public living_status: string = null,
              public death_date: string = null,
              public age_from: number = null,
              public age_to: number = null,
              public f_name: string = null,
              public m_name: string = null,
              public pincode: string = null,
              public qualification: string = null,
              public job: string = null,
              public blood_group: string = null,
              public blood_donate: string = null,
              public relationship: string = null,
              public zakath_giving: string = null,
              public place: string = null,
              public gender: string = null,
              public latitude: string = null,
              public longitude: string = null,
              public accuracy: string = null,
              public whatsapp: string = null,
              public family_name: string = null,
              public physical_status: string = null,
              public personal_interest: string = null,
              public social_interest: string = null,
              public role: any = null,
              // public location_ids: Array<number>[],
              // public department_ids: Array<number>[],
  ) {
    super();
  }
}


