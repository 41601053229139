import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'titlecase'
})
export class TitlecasePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        return this.toTitleCase(value);
    }

    toTitleCase(str: string) {
        return str.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
    }

}
