import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'searchMultipleFilter'
})
export class SearchMultipleFilterPipe implements PipeTransform {

  transform(list: any[], valueObj: string, nameObj: string, search: any[]): any {
    const data = [];
    for (let i = 0; i < search.length; i++) {
      data.push(list ? list.filter(item => item[valueObj].toString().search(search[i]) > -1)[0][nameObj] : null);
    }
    return data;

  }

}
