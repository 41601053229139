<!-- Sidebar Start -->
<div class="sidebar pe-4 pb-3">
  <nav class="navbar bg-light navbar-light">
    <a href="index.html" class="navbar-brand mx-4 mb-3">
      <h5 class="text-primary">Medappil Family</h5>
    </a>
    <div class="d-flex align-items-center ms-4 mb-4">
      <div class="position-relative">
        <nz-avatar nzIcon="user" [nzSrc]="avatarData"></nz-avatar>
        <!--        <img class="rounded-circle" src="assets/img/admin.png" alt="" style="width: 40px; height: 40px;">-->
        <div class="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
      </div>
      <div class="ms-3">
        <h6 class="mb-0">{{this.loggedInUserDetail.employee.member_name}}</h6>
        <span>{{this.loggedInUserDetail.user_type}}</span>
      </div>
    </div>
    <div class="navbar-nav w-100">
      <a href="index.html" class="nav-item nav-link"><i class="fa fa-tachometer-alt me-2"></i>Dashboard</a>
      <div class="nav-item dropdown"
           *ngIf="loggedInUserDetail.user_type=='admin' || loggedInUserDetail.employee.role.allow_setup">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i class="fas fa-cogs  me-2"></i>Settings</a>
        <div class="dropdown-menu bg-transparent border-0">
          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/GeneralSettings')"
              class="dropdown-item">General Settings</a>
          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/GlobalSettings')"
              class="dropdown-item">Global Settings</a>
          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/UserType')"

             class="dropdown-item">User Type</a>
        </div>
      </div>
<!--      <a *ngIf="loggedInUserDetail.user_type=='admin' || loggedInUserDetail.employee.role.allow_user_creation===true"-->
<!--         routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/member/add')"-->
<!--         class="nav-item nav-link"><i class="fas fa-user-tag"></i> Add Member</a>-->
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/member/search')"
         class="nav-item nav-link"><i class="fas fa-search"></i> Search Member</a>

      <div class="nav-item dropdown">
        <a href="" class="nav-link dropdown-toggle" data-bs-toggle="dropdown"><i class="fas fa-folder-plus"></i>Family
          Tree</a>
        <div class="dropdown-menu bg-transparent border-0">

          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/member/tree')"
             class="nav-item nav-link"><i class="fas fa-street-view"></i> Box Tree</a>

          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="pageOpen('/member/FoldTree')"
             class="nav-item nav-link"> <i class="fas fa-wind"></i> Collapsible Tree</a>

<!--          <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"-->
<!--             (click)="pageOpen('/member/hangingTree')"-->
<!--             class="nav-item nav-link"> <i class="fas fa-cannabis"></i> Hanging Tree</a>-->


        </div>
      </div>


    </div>
  </nav>
</div>
<!-- Sidebar End -->

<div class="content">
  <nav class="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
    <a href="index.html" class="navbar-brand d-flex d-lg-none me-4">
      <h2 class="text-primary mb-0"></h2>
    </a>
    <a href="#" class="sidebar-toggler flex-shrink-0" #menu>
      <i class="fa fa-bars"></i>
    </a>
    <app-search></app-search>
    <div class="navbar-nav align-items-center ms-auto">
      <app-message></app-message>
      <app-notification></app-notification>
      <app-profile></app-profile>
    </div>
  </nav>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
<a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="bi bi-arrow-up"></i></a>

<div id="complete" style="width: fit-content; height: fit-content;">
</div>

