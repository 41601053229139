import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NoWhitespaceDirective } from './directives/no-whitespace.directive';
import { TitlecasePipe } from './pipes/titlecase.pipe';
import {THIS_DOT_ERROR_IMAGE_PATH, THIS_DOT_LOADING_IMAGE_PATH, UseHttpImgSrcPipe} from './pipes/use-http-img-src.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SearchMultipleFilterPipe } from './pipes/search-multiple-filter.pipe';
import { AgeFromDobPipe } from './pipes/age-from-dob.pipe';
@NgModule({
  declarations: [DateAgoPipe, NoWhitespaceDirective, TitlecasePipe, UseHttpImgSrcPipe, SearchFilterPipe, SearchMultipleFilterPipe, AgeFromDobPipe],
  imports: [
    CommonModule
  ],
    exports: [
        DateAgoPipe,
        NoWhitespaceDirective,
        TitlecasePipe,
        UseHttpImgSrcPipe,
        SearchFilterPipe,
        SearchMultipleFilterPipe,
        AgeFromDobPipe
    ],
})
export class SharedModule {
  static forRoot(
    config: { loadingImagePath?: string; errorImagePath?: string } = {}
  ): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // set up the providers
        {
          provide: THIS_DOT_LOADING_IMAGE_PATH,
          useValue: config.loadingImagePath || null,
        },
        {
          provide: THIS_DOT_ERROR_IMAGE_PATH,
          useValue: config.errorImagePath || null,
        },
      ],
    };
  }
}
