import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AppService} from '../../app.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private appService: AppService,
    private router: Router,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(`Request for ${req.urlWithParams} started...`);
    // console.log("out",req)
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log(`Request for ${req.urlWithParams} completed...`);
          // console.log("in",req)
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(
          `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
        );


        if (error.status === 401) {
          console.log('error logged : ', error.status);
          Swal.fire({title: 'Error', text: error.message, icon: 'error'});
          this.handleAuthError();
        }
        // debugger;
        return throwError(error);
      })
    );
  }

  // Added By Althaf
  private handleAuthError() {
    localStorage.removeItem('me');
    localStorage.removeItem('accountAccessToken');
    this.router.navigate(['/login']);
  }

  // Ends
}
