<div class="nav-item dropdown">
  <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">

    <!--    <img class="rounded-circle me-lg-2" src="img/user.jpg" alt="" style="width: 40px; height: 40px;">-->
    <nz-avatar nzIcon="user" [nzSrc]="avatarData"></nz-avatar>

    <span class="d-none d-lg-inline-flex">{{this.loggedInUser.employee.member_name}}</span>
  </a>
  <div class="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
    <a href="#" class="dropdown-item" [routerLink]="['/member/view']" [queryParams]="{id:this.loggedInUser.employee.id}"
       routerLinkActive="active">My
      Profile</a>
    <a href="#" class="dropdown-item" [routerLink]="['/member/settings']" >Profile Settings</a>
    <a href="#" (click)="logout()" role="button" class="dropdown-item">Log Out</a>
  </div>
</div>
